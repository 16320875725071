import { BackdropLoader, NavigationBreadcrumbs, PrivateRoute, Role } from '@indigo-cloud/common-react';
import IconHubFill from '@indigo-cloud/common-react/src/images/icons/BT_Icons_Hub_Fill.svg';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import React, { useContext } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, useLocation } from 'react-router-dom';

import styles from './App.router.module.scss';
import { AwsExportsContext } from './components';

export enum AppRouteNames {
	Dashboard = 'Dashboard',
	Device = 'Device',
	DeviceInfo = 'DeviceInfo',
	DeviceGroupInfo = 'DeviceGroupInfo',
	DeviceFirmwareUpdate = 'DeviceFirmwareUpdate',
	DeviceGetParameterValues = 'DeviceGetParameterValues',
	DeviceSetParameterValues = 'DeviceSetParameterValues',
	DeviceAddParameterValues = 'DeviceAddParameterValues',
	DeviceDeleteParameterValues = 'DeviceDeleteParameterValues',
	DeviceExecuteCommand = 'DeviceExecuteCommand',
	DeviceTriggerPolicy = 'DeviceTriggerPolicy',
	DeviceGroupExecuteCommand = 'DeviceGroupExecuteCommand',
	DeviceGroupGetParameterValues = 'DeviceGroupGetParameterValues',
	DeviceGroupSetParameterValues = 'DeviceGroupSetParameterValues',
	DeviceSpeedTest = 'DeviceSpeedTest',
	Firmware = 'Firmware',
	FirmwareCreate = 'FirmwareCreate',
	FirmwareDelete = 'FirmwareDelete',
	FirmwareUpdate = 'FirmwareUpdate',
	FirmwareView = 'FirmwareView',
	Reports = 'Reports',
	ReportsCreate = 'ReportsCreate',
	ReportsDelete = 'ReportsDelete',
	ReportsUpdate = 'ReportsUpdate',
	ReportsDownload = 'ReportsDownload',
	NotFound = 'NotFound',
	DeviceGroup = 'DeviceGroup',
	DeviceGroupFirmwareUpdate = 'DeviceGroupFirmwareUpdate',
	JobDetailsForDevice = 'JobDetailsForDevice',
	JobDetailsForDeviceGroup = 'JobDetailsForDeviceGroup',
	DeviceGroupEditDevices = 'DeviceGroupEditDevices',
	DeviceGroupDelete = 'DeviceGroupDelete',
	DeviceGroupCreate = 'DeviceGroupCreate',
	DeviceGroupUpdate = 'DeviceGroupUpdate',
	AddDeviceGroup = 'AddDeviceGroup',
	DeviceGroupTriggerPolicy = 'DeviceGroupTriggerPolicy'
}

export const appRoutesFirmware = {
	[AppRouteNames.FirmwareCreate]: '/usp-controller/firmware/create',
	[AppRouteNames.FirmwareDelete]: '/usp-controller/firmware/delete/:firmwareName/:modelName',
	[AppRouteNames.FirmwareUpdate]: '/usp-controller/firmware/update/:firmwareName/:modelName',
	[AppRouteNames.FirmwareView]: '/usp-controller/firmware/:firmwareName/:modelName/view'
}

export const appRoutesReports = {
	[AppRouteNames.ReportsCreate]: '/usp-controller/reports/create',
	[AppRouteNames.ReportsDelete]: '/usp-controller/reports/delete/:reportId',
	[AppRouteNames.ReportsUpdate]: '/usp-controller/reports/update/:reportId',
	[AppRouteNames.ReportsDownload]: '/usp-controller/reports/download'
}

export const appRoutesDeviceGroup = {
	[AppRouteNames.DeviceGroupCreate]: '/usp-controller/deviceGroup/create',
	[AppRouteNames.DeviceGroupDelete]: '/usp-controller/deviceGroup/:id/delete',
	[AppRouteNames.DeviceGroupUpdate]: '/usp-controller/deviceGroup/:id/update'
}

export const appRoutes = {
	[AppRouteNames.Dashboard]: '/usp-controller',
	[AppRouteNames.Device]: '/usp-controller/device',
	[AppRouteNames.DeviceSpeedTest]: '/usp-controller/device/:agentEndpointId/speedtest/:type?',
	[AppRouteNames.DeviceInfo]: '/usp-controller/device/:agentEndpointId',
	[AppRouteNames.DeviceFirmwareUpdate]: '/usp-controller/device/:agentEndpointId/firmwareUpdate/:firmwareName?',
	[AppRouteNames.DeviceGetParameterValues]: '/usp-controller/device/:agentEndpointId/gpv/:parameterPaths?',
	[AppRouteNames.DeviceSetParameterValues]: '/usp-controller/device/:agentEndpointId/spv/:parameterPaths?',
	[AppRouteNames.DeviceAddParameterValues]: '/usp-controller/device/:agentEndpointId/apv/:parameterPaths?',
	[AppRouteNames.DeviceDeleteParameterValues]: '/usp-controller/device/:agentEndpointId/dpv/:parameterPaths?',
	[AppRouteNames.DeviceExecuteCommand]: '/usp-controller/device/:agentEndpointId/execute/:commands?',
	[AppRouteNames.DeviceTriggerPolicy]: '/usp-controller/device/:agentEndpointId/triggerPolicy',

	[AppRouteNames.Firmware]: '/usp-controller/firmware',

	[AppRouteNames.Reports]: '/usp-controller/reports',
	[AppRouteNames.DeviceGroup]: '/usp-controller/deviceGroup',
	[AppRouteNames.DeviceGroupInfo]: '/usp-controller/deviceGroup/:id',
	[AppRouteNames.DeviceGroupExecuteCommand]: '/usp-controller/deviceGroup/:id/execute/:commands?',
	[AppRouteNames.DeviceGroupGetParameterValues]: '/usp-controller/deviceGroup/:id/gpv/:parameterPaths?',
	[AppRouteNames.DeviceGroupSetParameterValues]: '/usp-controller/deviceGroup/:id/spv/:parameterPaths?',
	[AppRouteNames.DeviceGroupFirmwareUpdate]: '/usp-controller/deviceGroup/:id/firmwareUpdate/:firmwareName?',
	[AppRouteNames.DeviceGroupEditDevices]: '/usp-controller/deviceGroup/:id/action/:action',
	[AppRouteNames.DeviceGroupTriggerPolicy]: '/usp-controller/deviceGroup/:id/triggerPolicy',


	[AppRouteNames.JobDetailsForDevice]: '/usp-controller/device/:agentEndpointId/jobs/:jobId',
	[AppRouteNames.JobDetailsForDeviceGroup]: '/usp-controller/deviceGroup/:id/jobs/:jobId',

	[AppRouteNames.NotFound]: '/usp-controller/page-not-found',
	...appRoutesFirmware,
	...appRoutesReports,
	...appRoutesDeviceGroup
}

export const navigationItems = [
	{
		name: AppRouteNames.NotFound,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.Dashboard,
		shouldHideLink: true
	},
	{

		icon: IconHubFill,
		name: AppRouteNames.Device,
		roles: [Role.ReadOnly, Role.Device, Role.Admin]
	},
	{
		icon: GroupsIcon,
		name: AppRouteNames.DeviceGroup,
		roles: [Role.ReadOnly,  Role.DeviceGroup, Role.Admin]
	},
	{
		name: AppRouteNames.DeviceInfo,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.DeviceGroupInfo,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.JobDetailsForDevice,
		shouldHideLink: true
	},
	{
		icon: SettingsApplicationsIcon,
		name: AppRouteNames.Firmware,
		roles: [Role.ReadOnly, Role.Device, Role.DeviceGroup, Role.Firmware, Role.Admin]
	},
	{
		name: AppRouteNames.FirmwareCreate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.FirmwareUpdate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.FirmwareDelete,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.FirmwareView,
		shouldHideLink: true
	},
	{
		icon: AssessmentIcon,
		name: AppRouteNames.Reports,
		roles: [Role.Report, Role.Admin]
	},
	{
		name: AppRouteNames.ReportsCreate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReportsUpdate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReportsDelete,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReportsDownload,
		shouldHideLink: true
	}
];

export const appRouteNames = Object.keys(appRoutes);

const Loading = () => <BackdropLoader isLoading />;
export const pages = {
	[AppRouteNames.Dashboard]: Loadable({
		loader: () => import('./pages/Dashboard/Dashboard.page'),
		loading: Loading
	}),
	[AppRouteNames.Device]: Loadable({
		loader: () => import('./pages/Devices/Devices.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroup]: Loadable({
		loader: () => import('./pages/DeviceGroups/DeviceGroups.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceAddParameterValues]: Loadable({
		loader: () => import('./pages/DeviceAddParameterValues/DeviceAddParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceDeleteParameterValues]: Loadable({
		loader: () => import('./pages/DeviceDeleteParameterValues/DeviceDeleteParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceFirmwareUpdate]: Loadable({
		loader: () => import('./pages/DeviceFirmwareUpdate/DeviceFirmwareUpdate.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupFirmwareUpdate]: Loadable({
		loader: () => import('./pages/DeviceGroupFirmwareUpdate/DeviceGroupFirmwareUpdate.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupEditDevices]: Loadable({
		loader: () => import('./pages/DeviceGroupEditDevices/DeviceGroupEditDevices.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupDelete]: Loadable({
		loader: () => import('./pages/DeviceGroups/pages/DeviceGroupDelete/DeviceGroupDelete.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupCreate]: Loadable({
		loader: () => import('./pages/DeviceGroups/pages/DeviceGroupEditor/DeviceGroupEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupUpdate]: Loadable({
		loader: () => import('./pages/DeviceGroups/pages/DeviceGroupEditor/DeviceGroupEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGetParameterValues]: Loadable({
		loader: () => import('./pages/DeviceGetParameterValues/DeviceGetParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceSetParameterValues]: Loadable({
		loader: () => import('./pages/DeviceSetParameterValues/DeviceSetParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceExecuteCommand]: Loadable({
		loader: () => import('./pages/DeviceExecuteCommand/DeviceExecuteCommandInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceTriggerPolicy]: Loadable({
		loader: () => import('./pages/DeviceTriggerPolicy/DeviceTriggerPolicyInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupExecuteCommand]: Loadable({
		loader: () => import('./pages/DeviceGroupExecuteCommand/DeviceGroupExecuteCommandInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupGetParameterValues]: Loadable({
		loader: () => import('./pages/DeviceGroupGetParameterValues/DeviceGroupGetParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupSetParameterValues]: Loadable({
		loader: () => import('./pages/DeviceGroupSetParameterValues/DeviceGroupSetParameterValuesInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupTriggerPolicy]: Loadable({
		loader: () => import('./pages/DeviceGroupTriggerPolicy/DeviceGroupTriggerPolicyInputModal.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceSpeedTest]: Loadable({
		loader: () => import('./pages/DeviceSpeedTest/DeviceSpeedTest.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceInfo]: Loadable({
		loader: () => import('./pages/DeviceInfo/DeviceInfo.page'),
		loading: Loading
	}),
	[AppRouteNames.DeviceGroupInfo]: Loadable({
		loader: () => import('./pages/DeviceGroupInfo/DeviceGroupInfo.page'),
		loading: Loading
	}),
	[AppRouteNames.JobDetailsForDevice]: Loadable({
		loader: () => import('./pages/JobDetails/JobDetails.page'),
		loading: Loading
	}),
	[AppRouteNames.JobDetailsForDeviceGroup]: Loadable({
		loader: () => import('./pages/JobDetails/JobDetails.page'),
		loading: Loading
	}),
	[AppRouteNames.Firmware]: Loadable({
		loader: () => import('./pages/Firmware/FirmwareListEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.FirmwareCreate]: Loadable({
		loader: () => import('./pages/Firmware/pages/FirmwareEditor/FirmwareEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.FirmwareUpdate]: Loadable({
		loader: () => import('./pages/Firmware/pages/FirmwareEditor/FirmwareEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.FirmwareDelete]: Loadable({
		loader: () => import('./pages/Firmware/pages/FirmwareDelete/FirmwareDelete.page'),
		loading: Loading
	}),
	[AppRouteNames.FirmwareView]: Loadable({
		loader: () => import('./pages/Firmware/pages/FirmwareView/FirmwareView.page'),
		loading: Loading
	}),
	[AppRouteNames.Reports]: Loadable({
		loader: () => import('./pages/Reports/ReportListEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReportsCreate]: Loadable({
		loader: () => import('./pages/Reports/pages/ReportEditor/ReportEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReportsUpdate]: Loadable({
		loader: () => import('./pages/Reports/pages/ReportEditor/ReportEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReportsDelete]: Loadable({
		loader: () => import('./pages/Reports/pages/ReportDelete/ReportDelete.page'),
		loading: Loading
	}),
	[AppRouteNames.ReportsDownload]: Loadable({
		loader: () => import('./pages/Reports/pages/ReportDownload/ReportDownload.page'),
		loading: Loading
	}),
	[AppRouteNames.NotFound]: Loadable({
		loader: () => import('./pages/NotFound/NotFound.page'),
		loading: Loading
	})
};

interface AppRouterProperties {}

export const AppRouter: React.FC<AppRouterProperties> = () => {
	const contextAwsExports = useContext(AwsExportsContext);
	const location = useLocation();
	return (
		<div
			className={styles.container}
		>
			<NavigationBreadcrumbs routes={appRoutes} />
			<Switch location={location}>
				{/* Private routes */}
				<PrivateRoute roles={[Role.ReadOnly, Role.Device, Role.Firmware, Role.DeviceGroup, Role.Admin, Role.Report]} path={appRoutes.Dashboard} exact component={pages[AppRouteNames.Dashboard]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.Device, Role.Admin]} path={appRoutes.Device} exact component={pages[AppRouteNames.Device]} awsExports={contextAwsExports} />

				<PrivateRoute roles={[Role.ReadOnly,  Role.DeviceGroup, Role.Admin]} path={appRoutes.DeviceGroup} exact component={pages[AppRouteNames.DeviceGroup]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.DeviceGroup, Role.Admin]} path={appRoutes.JobDetailsForDeviceGroup} component={pages[AppRouteNames.JobDetailsForDeviceGroup]} awsExports={contextAwsExports} />
				{/* TODO: Re-defining the create device group route here and on the deviceGroups page. Find a better way to handle deviceGroups/ (with deviceGroups/create nested route) and deviceGroups/:id (with deviceGroups/:id/update and deviceGroups/:id/delete nested route). Use same pattern from device page  */}
				<PrivateRoute roles={[ Role.DeviceGroup, Role.Admin]} path={appRoutesDeviceGroup[AppRouteNames.DeviceGroupCreate]} exact component={pages[AppRouteNames.DeviceGroup]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.DeviceGroup, Role.Admin]} path={appRoutes.DeviceGroupInfo} component={pages[AppRouteNames.DeviceGroupInfo]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.Device, Role.Admin]} path={appRoutes.JobDetailsForDevice} component={pages[AppRouteNames.JobDetailsForDevice]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.Device, Role.Admin]} path={appRoutes.DeviceInfo} component={pages[AppRouteNames.DeviceInfo]} awsExports={contextAwsExports} />

				<PrivateRoute roles={[Role.ReadOnly, Role.Device, Role.DeviceGroup, Role.Firmware, Role.Admin]} path={appRoutes.Firmware} component={pages[AppRouteNames.Firmware]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.ReadOnly, Role.Report, Role.Admin]} path={appRoutes.Reports} component={pages[AppRouteNames.Reports]} awsExports={contextAwsExports} />
				{/* Fallback catch-all route */}
				<Route component={pages[AppRouteNames.NotFound]} />
			</Switch>
		</div>
	);
};

AppRouter.propTypes = {};
