import { DeepReadonlyObject, OperationState, PaginatedResults, getLoadingStatusInProgress, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';
import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { Job, JobExecution } from '../../../shared';
import { DOWNLOAD_JOB, DOWNLOAD_JOB_CLEAR, DOWNLOAD_JOB_ERROR, DOWNLOAD_JOB_SUCCESS, LOAD_DEVICE_GROUP_JOBS, LOAD_DEVICE_GROUP_JOBS_ERROR, LOAD_DEVICE_GROUP_JOBS_SUCCESS, LOAD_DEVICE_JOBS, LOAD_DEVICE_JOBS_ERROR, LOAD_DEVICE_JOBS_SUCCESS, LOAD_JOB_DETAILS, LOAD_JOB_DETAILS_ERROR, LOAD_JOB_DETAILS_SUCCESS, LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE, LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR, LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS } from '../../constants';

export type JobsState = DeepReadonlyObject<{
	downloadJobOperation: {
		[id: string]: OperationState<any, Error>
	};
	getJobDetails: {
		[id: string]: OperationState<Job>;
	},
	getJobsOperation: {
		[id: string]: OperationState<{ [pageNumber: number]: PaginatedResults<Job>}>;
	};
	getJobExecutionDetails: {
		[id: string]: OperationState<JobExecution>;
	}
}>;
export const initialJobState: JobsState = {

	getJobDetails: {},
	getJobExecutionDetails: {},
	getJobsOperation: {},
	downloadJobOperation: {}
};

export const jobsReducers = combineReducers<JobsState, any>({
	downloadJobOperation: (state = initialJobState.downloadJobOperation, action) => {
		switch (action.type) {
		case DOWNLOAD_JOB: {
			const jobId = action.payload.jobId;
			return {
				...state,
				[jobId]: getStateInProgress(action.payload)
			}
		}
		case DOWNLOAD_JOB_SUCCESS: {
			const { jobId, response } = action.payload;
			return {
				...state,
				[jobId]: getStateSuccess(response)
			};
		}
		case DOWNLOAD_JOB_ERROR: {
			const { jobId, error } = action.payload;

			return {
				...state,
				[jobId]: getStateError(error)
			}
		}
		case DOWNLOAD_JOB_CLEAR: {
			const { jobId } = action.payload;
			return {
				[jobId]: getStateInitial()
			};
		}
		default:
			return state;
		}
	},
	getJobDetails: (state = initialJobState.getJobDetails, action:any) => {
		switch (action.type) {
		case LOAD_JOB_DETAILS: {
			const { jobId } = action.payload.jobId;
			return {
				[jobId]: getStateInProgress(action.payload.jobId)
			};
		}
		case LOAD_JOB_DETAILS_SUCCESS: {
			const { jobId, response } = action.payload;
			return {
				[jobId]: getStateSuccess(response)
			};
		}
		case LOAD_JOB_DETAILS_ERROR: {
			const { error, jobId } = action.payload;

			return {
				[jobId]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},
	getJobExecutionDetails: (state = initialJobState.getJobExecutionDetails, action:any) => {
		switch (action.type) {
		case LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE: {
			const { jobId } = action.payload.jobId;
			return {
				[jobId]: getStateInProgress(action.payload.jobId)
			};
		}
		case LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS: {
			const { jobId, response } = action.payload;
			return {
				[jobId]: getStateSuccess(response)
			};
		}
		case LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR: {
			const { error, jobId } = action.payload;

			return {
				[jobId]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},
	getJobsOperation: (state = initialJobState.getJobsOperation, action:any) => {
		switch (action.type) {
		case LOAD_DEVICE_GROUP_JOBS: {
			const { name } = action.payload;
			return {
				[name]: {
					...state[name],
					...getLoadingStatusInProgress(),
					status: getLoadingStatusInProgress()
				}
			};
		}
		case LOAD_DEVICE_GROUP_JOBS_SUCCESS: {
			const { name, result } = action.payload;
			return {
				[name]: getStateSuccess({
					...state[name].current,
					...result
				}, state[name])
			};
		}
		case LOAD_DEVICE_GROUP_JOBS_ERROR: {
			const { error, name } = action.payload;

			return {
				[name]: getStateError(error)
			};
		}
		case LOAD_DEVICE_JOBS: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getLoadingStatusInProgress(),
					status: getLoadingStatusInProgress()
				}
			};
		}
		case LOAD_DEVICE_JOBS_SUCCESS: {
			const { agentEndpointId, result } = action.payload;
			return {
				[agentEndpointId]: getStateSuccess({
					...state[agentEndpointId].current,
					...result
				}, state[agentEndpointId])
			};
		}
		case LOAD_DEVICE_JOBS_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				[agentEndpointId]: getStateError(error)
			};
		}
		default:
			return state;
		}
	}

});

export type jobsReducers = StateType<typeof jobsReducers>;
