import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JobDetailsActions } from '../../../store/actions';
import { jobsSelectors } from '../../../store/selectors';
import styles from './JobDetails.component.module.scss';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Details } from '@indigo-cloud/common-react';
import Typography from '@mui/material/Typography';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { Job } from '../../types';


type JobDetailsProperties = {
	jobId: string;
	agentEndpointId: string;
	executionNumber: number;
};

interface TabPanelProperties {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

// TODO: Centralise this
export function TabPanel(properties: TabPanelProperties) {
	const { children, value, index, ...other } = properties;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box width="100%">
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const getTabAttributes = (index: number) => {
	return {
		'aria-controls': `widget-tabpanel-${index}`,
		id: `widget-tabpanel-${index}`
	};
}

export const jobDetailsDataSelectors = {
	container: 'JobDetails-container'
};

const downloadFiles = (url: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', ''); // Set a default filename or use dynamic names if possible
	document.body.append(link);
	link.click();
	link.remove();
};


export const JobDetails: React.FC<JobDetailsProperties> = ({ jobId, agentEndpointId = '', executionNumber = 0 }) => {
	const getJobDetailsOperation = useSelector(jobsSelectors.getJobDetails(jobId));
	const getJobExecutionDetailsOperation = useSelector(jobsSelectors.getJobExecutionDetails(jobId));
	const dispatch = useDispatch();
	const [value, setValue] = React.useState(0);
	/* eslint-disable-next-line unicorn/no-null */
	const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
	const hasFailures = !!(getJobDetailsOperation?.result?.numberOfFailed && getJobDetailsOperation?.result?.numberOfFailed > 0) || (!!(getJobDetailsOperation?.result?.numberOfTimedOut && getJobDetailsOperation?.result?.numberOfTimedOut > 0));
	const isDownloadAllowed = jobId.toLowerCase().includes('get-group') || hasFailures;

	useEffect(() => {
		dispatch(JobDetailsActions.loadJobDetails(jobId));
		if (agentEndpointId && executionNumber) {
			dispatch(JobDetailsActions.loadDeviceJobExecutionDetails(jobId, agentEndpointId, executionNumber));
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const downloadJobOperation = useSelector(jobsSelectors.downloadJobOperation(jobId));

	// Automatically download files when downloadJobOperation updates
	useEffect(() => {
		if (downloadJobOperation?.result?.length) {
			const currentFileUrl = downloadJobOperation.result[0];
			downloadFiles(currentFileUrl);
		}
	}, [downloadJobOperation?.result]);

	// Function to open the menu
	const handleClick = (event: { currentTarget: any; }) => {
		setAnchorElement(event.currentTarget);
	};

	const handleClose = () => {
		/* eslint-disable-next-line unicorn/no-null */
		setAnchorElement(null);
	};

	const handleJobOption = (fileType: string) => {
		dispatch(JobDetailsActions.downloadJob(jobId, fileType));
		/* eslint-disable-next-line unicorn/no-null */
		setAnchorElement(null);
	}

	const handleDownloadSummary = () => {
		handleJobOption('jobSummary');
	};
	
	// Pre-bound function for 'Failure summary' option
	const handleDownloadFailureSummary = () => {
		handleJobOption('jobFailureSummary');
	};

	const renderExecutionSummary = () => (
		<Grid container spacing={2}>
			{['Succeeded', 'Failed', 'Cancelled', 'Rejected', 'Queued', 'In progress', 'Removed', 'Timedout'].map((label, index) => (
				<Grid key={label} item xs={6} md={3}>
					<Box sx={{ textAlign: 'left' }}>
						<Box>{label}</Box>
						<Box sx={{ fontWeight: 'bold' }}>
							{getJobDetailsOperation?.result?.[`numberOf${label.replace(' ', '')}` as keyof Job] ?? 0}
						</Box>
					</Box>
				</Grid>
			))}
		</Grid>
	);

	const renderDownloadMenu = () => (
		<Menu anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleClose}>
			{jobId.toLowerCase().includes('get-group') && (
				 <MenuItem onClick={handleDownloadSummary}>Download summary</MenuItem>
			)}
			{hasFailures && (
				<MenuItem onClick={handleDownloadFailureSummary}>Failure summary</MenuItem>
			)}
		</Menu>
	);


	const generateView = () => (
		<div data-cy={jobDetailsDataSelectors.container} className={styles.container} >
			<Box>
				<Tabs value={value} onChange={handleChange} aria-label="Tab for job and job executions">
					<Tab label="Details" {...getTabAttributes(0)} />
					<Tab label="Job executions" {...getTabAttributes(1)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className={styles.subContainer}>
						<div className={styles.header}>
							<h3>Job details</h3>
							{(getJobDetailsOperation?.result?.status === 'COMPLETED') && isDownloadAllowed && (
								<div>
									<IconButton
										onClick={handleClick}
										size="large"
										disabled={downloadJobOperation?.isLoading}
									>
										{downloadJobOperation?.isLoading ? (
											<CircularProgress size={24} />
										) : (
											<FileDownloadOutlined />
										)}
									</IconButton>
									{renderDownloadMenu()}
								</div>
							)}
						</div>
						{
							getJobDetailsOperation?.result && <Details object={(({ jobId, startedAt, status, updatedAt }) => ({ jobId, startedAt, status, updatedAt }))(getJobDetailsOperation.result)} />
						}
					</div>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<div className={styles.subContainer}>
						<h3>Execution overview</h3>
						{renderExecutionSummary()}
					</div>
					{
						(agentEndpointId && executionNumber) ? <>
							<div className={styles.subContainer}>
								<h3>Job Execution Summary</h3>
								<Details excludedLabels={['statusDetails', 'detailsMap']} object={getJobExecutionDetailsOperation?.result || {}} />
							</div></> : undefined
					}
				</TabPanel>
			</Box>
		</div>);

	return (
		<>
			{(getJobDetailsOperation && !getJobDetailsOperation.isLoading) ? generateView() : <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div>}
		</>
	);
};
