/**
 * LOAD_JOB_DETAILS
 */
export const LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE = 'JOB.LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE';
export const LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS = 'JOB.LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS';
export const LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR = 'JOB.LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR';

export const LOAD_JOB_DETAILS = 'JOB.LOAD_JOB_DETAILS';
export const LOAD_JOB_DETAILS_SUCCESS = 'JOB.LOAD_JOB_DETAILS_SUCCESS';
export const LOAD_JOB_DETAILS_ERROR = 'JOB.LOAD_JOB_DETAILS_ERROR';

/**
 * DOWNLOAD_JOB
 */
export const DOWNLOAD_JOB = 'JOB.DOWNLOAD_JOB';
export const DOWNLOAD_JOB_SUCCESS = 'JOB.DOWNLOAD_JOB_SUCCESS';
export const DOWNLOAD_JOB_ERROR = 'JOB.DOWNLOAD_JOB_ERROR';
export const DOWNLOAD_JOB_CLEAR = 'JOB.DOWNLOAD_JOB_CLEAR';